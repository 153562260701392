import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DynamicImage, LoadingPage, PageContainer, Seo } from 'components';
import Header from 'components/Header';
import EmailForm from './components/EmailForm';
import { tablet, useQuery } from 'styles/breakpoints';
import { Analytics } from 'apis/Analytics';
import { createLead, updateQuizAnswers, updateUser } from 'state/user/effects';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import Text from 'components/Text';
import { usePageView, useQuizData, useTrackingQueryParams } from 'utils/hooks';
import { Events } from 'utils/events';
import { cliendCodeToUrl } from 'utils/cliendCodeToUrl';
import { normalizeStates } from 'utils/localization';
import Tracking from 'utils/tracking';
import { leadLanguage } from 'utils/functions';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  padding: 3rem 1rem 6rem 1rem;
  @media ${tablet} {
    padding: 3rem 1rem;
    max-width: 100%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 30rem;
  width: 100%;
`;

const Subtitle = styled(Text)`
  margin-top: 1.5rem;
`;

const Footer = styled(Text)`
  max-width: 30rem;
  @media ${tablet} {
    margin-bottom: 1.5rem;
  }
`;

const ImageContainer = styled.div`
  margin: 4rem 0rem;
  width: 16rem;
  height: 16rem;

  @media ${tablet} {
    width: 7.5rem;
    height: 7.5rem;
  }
`;

const EmailFormStyled = styled(EmailForm)`
  margin: 1rem 0.5rem;
`;

const Title = styled(Text)`
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.016em;
  text-align: left;
`;

const Email: FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const trackingParams = useTrackingQueryParams();
  const { isTablet } = useQuery();

  const { quiz_answers, user, geolocation, user_ip } = useSelector(
    (state: AppState) => state.user,
  );
  const config = useSelector((state: AppState) => state.config);
  const variant = config?.variant;

  const quiz = new URLSearchParams(location.search).get('qz') ?? 'main-mw';

  const dispatch = useDispatch();

  const { goToCheckout, goToResults } = useRouter();

  usePageView({
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
  });

  const handleNextRoute = () => {
    if (variant === 'email-first') {
      return location.search.includes('tst3')
        ? goToCheckout({ discount: false })
        : goToResults();
    }
    goToCheckout({ discount: false });
  };

  const handleGetResults = async (email: string) => {
    setError('');

    setLoading(true);

    Tracking.trackCTAButton(location.pathname);

    if (!user?.email || email !== user.email) {
      dispatch(dispatch(updateUser({ email, password: '' })));
    }

    quiz_answers.quiz = quiz;

    try {
      const code =
        (await dispatch(
          createLead({
            email,
            funnel: quiz,
            quiz_answers: { ...quiz_answers, ...trackingParams },
            language: leadLanguage(quiz),
          }),
        )) ?? '';

      dispatch(updateQuizAnswers(trackingParams));

      cliendCodeToUrl(code);
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('steps')) {
        urlParams.delete('steps');
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
        window.history.replaceState(null, '', newUrl);
      }

      const userState = normalizeStates(
        geolocation?.iso_country || '',
        geolocation?.iso_state || '',
      );

      Tracking.leadCreated(
        code,
        email,
        geolocation?.iso_country,
        quiz_answers?.gender ? quiz_answers?.gender[0] : null,
        userState,
        user_ip,
      );

      Events.checkoutPageVisit(code);

      handleNextRoute();
    } catch (error) {
      setError(error?.response.data?.errors?.email ?? '');

      setLoading(false);
    }
  };

  const data = useQuizData('email');

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <PageContainer with100vh>
        <Container>
          <Seo />
          <Header logoVariant="center" />
          <ContentContainer>
            <Title
              type={isTablet ? 'h2600' : 'h1'}
              textAlign="left"
              color="dark100"
              dangerouslySetInnerHTML={{ __html: data?.title }}
            />
            <EmailFormStyled
              loading={loading}
              error={error}
              onSubmit={handleGetResults}
              continueBtnTitle={data?.continueBtnTitle}
              placeholder={data?.inputPlaceholder}
            />
            {data.img && (
              <ImageContainer>
                <DynamicImage
                  src={data.img}
                  alt={data.alt}
                  width="100%"
                  height="100%"
                />
              </ImageContainer>
            )}
          </ContentContainer>
          <Footer
            type={isTablet ? 'bodyXS' : 'bodyS'}
            textAlign="center"
            color="dark60"
          >
            {data?.footer}
          </Footer>
        </Container>
      </PageContainer>
    </>
  );
};

export default Email;
